// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //API:"http://192.168.1.6:8080/api",
  //API_PHOTOS:"http://192.168.1.6:8080/Photos"     http://192.168.1.166:8080/
  //API: "http://localhost:50903/api", // "https://robasaapi.mdg.dev/api", //"https://robasa-api.azurewebsites.net/api", //
  //API_PHOTOS: "http://localhost:50903/Photos" // "https://robasaapi.mdg.dev/Photos" // "https://robasa-api.azurewebsites.net/Photos" //


  //En Robasa
  //API:"http://192.168.1.166:8080/api",
  //API_PHOTOS:"http://192.168.1.166:8080/Photos"     

  // En desarrollo
  API: "https://robasaapi.mdg.dev/api", //"https://robasa-api.azurewebsites.net/api", //
  API_PHOTOS: "https://robasaapi.mdg.dev/Photos" // "https://robasaapi.mdg.dev/Photos"
};

/*
  http://localhost:5000/

  API:"http://localhost:5000/api",
  API_PHOTOS:"http://localhost:5000/Photos"

  API:"https://robasaapi.azurewebsites.net/api",
  API_PHOTOS:"https://robasaapi.azurewebsites.net/Photos"

 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
