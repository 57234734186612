import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-num-pad',
  templateUrl: './num-pad.component.html',
  styleUrls: ['./num-pad.component.css']
})
export class NumPadComponent implements OnInit {
  public numberReading: string;
  public type: any;
  public y: number;
  public actualPickqtty: number;
  public actualBoxqtty: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NumPadComponent>,
    private _toastr: ToastrService ) { 

    }

  ngOnInit(): void {
    //alert(this.data);
    this.y = 0;
    this.numberReading = "";
    this.type = this.data.type;
    this.actualPickqtty = this.data.asignDetail.pickqtty - this.data.asignDetail.picked
    this.actualBoxqtty = this.data.asignDetail.boxqtty - this.data.asignDetail.boxpicked
  }

  ngOnDestroy(){
    this.dialogRef.close(this.data);
  }

  onAcceptClick(): void {
    if(this.numberReading === "" || this.y <= 0){
      this._toastr.info('Ingrese al menos un elemento')
      return;
    } else {
      if(this.data.type == 0) {
        if (this.y <= this.actualPickqtty) {
          this.data.asignDetail.picked += this.y;
        } else {
          this._toastr.info('El artículo no debe exceder el número máximo de artículos');
          return;
        }
      } else if (this.data.type == 1) {
        if(this.y <= this.actualBoxqtty) {
          this.data.asignDetail.boxpicked += this.y;
        } else {
          this._toastr.info('El artículo no debe exceder el número máximo de artículos');
          return;
        }
      }
      this.data._action = 'ACCEPT';
      this.dialogRef.close();
    }
  }
  
  onCloseClick() {
    this.data._action = 'CANCEL';
    this.dialogRef.close();
  }

  setNumber(digitNumber: number) : void {
    this.numberReading += digitNumber.toString();
    this.y = +this.numberReading;
  }

  unsetNumber() {
    this.numberReading = this.numberReading.substring(0, this.numberReading.length - 1);
    this.y = +this.numberReading;
  }
}
