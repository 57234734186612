import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  readonly APIUrl=environment.API;
  
  constructor(private http:HttpClient) { }

  postLogin(val:any) {
    return this.http.post(this.APIUrl+'/Login',val);
  }
}
