import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { BehaviorSubject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn = new BehaviorSubject<boolean>(false);
  authStatus = this.loggedIn.asObservable();

  changeAuthStatus(value: boolean) {
    this.loggedIn.next(value)
  }

  admin = new BehaviorSubject<boolean>(false);
  authStatusAdmin = this.admin.asObservable();

  changeAuthStatusAdmin(value: boolean) {
    this.admin.next(value)
  }

  picking = new BehaviorSubject<boolean>(false);
  authStatusPicking = this.picking.asObservable();

  changeAuthStatusPicking(value: boolean) {
    this.picking.next(value)
  }

  constructor() {
    this.loggedInn();
  }

  loggedInn(): void {
    if (localStorage.getItem("accessToken") == "" || localStorage.getItem("accessToken") == null || localStorage.getItem("accessToken") == "0") {
      this.changeAuthStatus(false);
    } else {
      this.changeAuthStatus(true);
    }
  }

  loadInLocalStorange(item: string) {
    return localStorage.getItem(item);
  }

  SetLocalStorange(item: string, data: string) {
    localStorage.setItem(item, data);
  }

  getDecodedAccessToken(token: string): any {
    try {
      let i = jwt_decode(token);
      this.loadToken(i);
      return i;
    }
    catch (Error) {
      return null;
    }
  }

  getRoles(token: string): any {
    try {
      let i = jwt_decode(token);
      return this.loadRoles(i);;
    }
    catch (Error) {
      return null;
    }
  }

  loadRoles(token) {
    var object = JSON.parse(token.Roles);

    if (object.isadmin) {
      this.changeAuthStatusAdmin(true);
      this.changeAuthStatusPicking(true);
    } else {
      if (object.ispicker)
        this.changeAuthStatusPicking(true);
    }

    return token.Roles;
  }

  loadToken(token) {
    this.SetLocalStorange("image", environment.API_PHOTOS + "/" + token.PhotoPath);
    this.SetLocalStorange("userid", token.Id + "");
    this.SetLocalStorange("username", token.sub);
  }

  getUserId() {
    return localStorage.getItem("userid");
  }

  logout() {
    localStorage.removeItem("username");
    localStorage.removeItem("userid");
    localStorage.removeItem("image");
    localStorage.removeItem("accessToken");

    localStorage.removeItem("isadmin");
    localStorage.removeItem("isassistant");
    localStorage.removeItem("iscollector");
    localStorage.removeItem("isdriver");
    localStorage.removeItem("ispicker");

    this.changeAuthStatus(false);
    this.changeAuthStatusAdmin(false);
    this.changeAuthStatusPicking(false);
  }
}


