import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AsignService {
  readonly APIUrl=environment.API;

  constructor(private http:HttpClient) { }

  getHeaders(){
    let httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
    });
    return httpHeaders;
  }

  getAsiList():Observable<any[]>{
    return this.http.get<any>(this.APIUrl+'/Asign',{headers:this.getHeaders()});
  }
  
  getAsiListPaged(paginationObject: any):Observable<any[]>{
    return this.http.post<any>(this.APIUrl+'/Asign/paged', paginationObject,{headers:this.getHeaders()});
  }

  addAsign(val:any){
    return this.http.post(this.APIUrl+'/Asign',val,{headers:this.getHeaders()});
  }

  updateAsign(val:any){
    return this.http.put(this.APIUrl+'/Asign',val,{headers:this.getHeaders()});
  }

  deleteAsign(val:any){
    return this.http.delete(this.APIUrl+'/Asign/'+val,{headers:this.getHeaders()});
  }

  getAsiPickerList(val:any):Observable<any[]>{
    return this.http.get<any>(this.APIUrl+'/Asign/Picker/'+val,{headers:this.getHeaders()});
  }

  updateStatus(status: any, val: any) {
    return this.http.put<any>(this.APIUrl+'/Asign/ChangeStatus/'+status, val, {headers:this.getHeaders()});
  }

  updateStatusForce(status: any, val: any) {
    return this.http.get<any>(this.APIUrl+'/Asign/ChangeStatus/Force/'+status+"/"+val, {headers:this.getHeaders()});
  }


}
