import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { AuthService } from 'app/services/auth-login.service';
import { LoginService } from 'app/services/login.service';
import Swal from 'sweetalert2';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit{

    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;

    title = 'Robasa';

    isExpanded = false;
    showSubmenu: boolean = false;
    isShowing = false;
    showSubSubMenu: boolean = false;
    email: string = '';
    password: string = '';
    loggedIn: boolean = false;
  
    imgUser:string = "https://source.unsplash.com/random/60x60";
    user:string = "Anónimo";
  
    admin:boolean;
    picker:boolean;

    public isCollapsed = true;
    @ViewChild("navbar-cmp", {static: false}) button;

    constructor(location:Location, private renderer : Renderer2, private element : ElementRef, public auth: AuthService, public service: LoginService,private router: Router) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit(){
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        this.router.events.subscribe((event) => {
          this.sidebarClose();
       });

       this.loadProfile();
       this.auth.getRoles(this.auth.loadInLocalStorange("accessToken"));
   
       this.auth.authStatusAdmin.subscribe(value => this.admin = value);
       this.auth.authStatusPicking.subscribe(value => this.picker = value);
       this.auth.authStatus.subscribe((value) => (this.loggedIn = value));
       
    }
    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
      }
      sidebarOpen() {
          const toggleButton = this.toggleButton;
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          setTimeout(function(){
              toggleButton.classList.add('toggled');
          }, 500);

          html.classList.add('nav-open');
          if (window.innerWidth < 991 && mainPanel && mainPanel.style) {
            mainPanel.style.position = 'fixed';
          }
          this.sidebarVisible = true;
      };
      sidebarClose() {
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          if (window.innerWidth < 991 && mainPanel && mainPanel.style) {
            setTimeout(function(){
              mainPanel.style.position = '';
            }, 500);
          }
          this.toggleButton.classList.remove('toggled');
          this.sidebarVisible = false;
          html.classList.remove('nav-open');
      };
      collapse(){
        this.isCollapsed = !this.isCollapsed;
        const navbar = document.getElementsByTagName('nav')[0];
        
        if (!this.isCollapsed) {
          navbar.classList.remove('navbar-transparent');
          navbar.classList.add('bg-white');
        }else{
          navbar.classList.add('navbar-transparent');
          navbar.classList.remove('bg-white');
        }

      }

  logout() {
    this.auth.logout();
    this.auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
    Swal.fire({
      icon: 'success',
      title: '',
      text: '¡Has cerrado la sesión!',
    });
  }


  onImgError(event) {
    event.target.src = 'https://source.unsplash.com/random/60x60';
  }

  //Esta funcion es util para producción para mantener la consola limpia por el tema de las imagenes que no logra cargar
  urlImage(url:any) {
    var req = new XMLHttpRequest();
    req.open('GET', url, false);
    req.send();
    if (req.status == 200){
      return url;
    }else if (req.status == 404){
      console.clear();
      console.info("Se limpia la consola por que las imagenes de perfil no estan cargadas");
      return './assets/img/logo.jpg';
    }
  }

  loadProfile(){
    //Acán van a ir lo de roles
    this.imgUser = this.auth.loadInLocalStorange("image");
    this.user = this.auth.loadInLocalStorange("username");
  }

}
