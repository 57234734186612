import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AsignDetailService {
  readonly APIUrl=environment.API;

  constructor(private http:HttpClient) { }

  getHeaders(){
    let httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
    });
    return httpHeaders;
  }
  
  getAsiList(val:any):Observable<any[]>{
    return this.http.get<any>(this.APIUrl+'/Asign/'+val,{headers:this.getHeaders()});
  }

  getAsiListForScan(val:any):Observable<any[]>{
    return this.http.get<any>(this.APIUrl+'/Asign/AvailableForScan/'+val,{headers:this.getHeaders()});
  }

  getAsiListByEmployee(val:any,employee:any):Observable<any[]>{
    return this.http.get<any>(this.APIUrl+'/Asign/'+val+"/"+employee,{headers:this.getHeaders()});
  }
  
  getAsiClassesList(val:any):Observable<any[]>{
    return this.http.get<any>(this.APIUrl+'/AsignDetail/classes/'+val,{headers:this.getHeaders()});
  }

  setAsiClassesList(val:any):Observable<any[]>{
    return this.http.post<any>(this.APIUrl+'/AsignDetail/SetEspecialist/',val,{headers:this.getHeaders()});
  }

  setAsiClassesListDefault(id:any):Observable<any>{
    return this.http.get<any>(this.APIUrl+'/AsignDetail/SetEspecialistDefault/'+id,{headers:this.getHeaders()});
  }

  setAsiAvailableForScan(id:any):Observable<any>{
    return this.http.get<any>(this.APIUrl+'/AsignDetail/AvailableForScan/'+id,{headers:this.getHeaders()});
  }

  setAsiFreeList(val:any):Observable<any[]>{
    return this.http.post<any>(this.APIUrl+'/AsignDetail/FreePick/',val,{headers:this.getHeaders()});
  }

  getScan(asignId: any, barcode: any) {
    return this.http.get<any>(this.APIUrl+'/Scan?asignid='+asignId+'&barcode='+barcode,{headers:this.getHeaders()});
  }
}
