import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/services/auth-login.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    active: boolean;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',  title: 'Dashboard',        icon:'fas fa-bank',         class: '' , active: true},
    { path: '/employee',   title: 'Colaboradores',    icon:'fas fa-users',        class: '' , active: true}, 
    { path: '/department', title: 'Andenes',          icon:'fas fa-boxes',        class: '' , active: true}, 
    { path: '/truck',      title: 'Camiones',         icon:'fas fa-truck-moving', class: '' , active: true},
    { path: '/asign',      title: 'Asignaciones',     icon:'far fa-list-alt',     class: '' , active: true},
    { path: '/piking',     title: 'Recolección',      icon:'fas fa-dolly',        class: '' , active: true},
    { path: '/packing',    title: 'Carga en andenes', icon:'fas fa-cart-plus',    class: '' , active: true},
    { path: '/approval',   title: 'Aprobación',       icon:'far fa-check-circle', class: '' , active: true},
    { path: '/configure',  title: 'Clasificaciones',  icon:'fas fa-tools',        class: '' , active: true},
    { path: '/sync',       title: 'Importación',      icon:'fas fa-cloud-download-alt',     class: '' , active: true},
    //{ path: '/logout',     title: 'Cerrar Sesión',    icon:'fas fa-sign-out-alt', class: '' , active: true},
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class SidebarComponent implements OnInit {

    admin:boolean;
    picker:boolean;
    
    public menuItems: any[];

    constructor(public auth: AuthService,private router: Router) { 
        
    }

    ngOnInit() {
        this.auth.authStatusAdmin.subscribe(value => this.admin = value);
        this.auth.authStatusPicking.subscribe(value => this.picker = value);

        ROUTES[0].active = true;
        ROUTES[1].active = this.admin;
        ROUTES[2].active = this.admin;
        ROUTES[3].active = this.admin;
        ROUTES[4].active = this.admin;
        ROUTES[5].active = this.picker;
        ROUTES[6].active = true;
        ROUTES[7].active = this.admin; 
        ROUTES[8].active = this.admin;
        ROUTES[9].active = this.admin;

        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    logoutFromNavBar(){
        this.auth.logout();
        this.auth.changeAuthStatus(false);
        this.router.navigateByUrl('/login');
        Swal.fire({
        icon: 'success',
        title: '',
        text: '¡Has cerrado la sesión!',
        });
    }
}
