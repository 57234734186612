import { Component, ViewChild } from '@angular/core';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { AuthService } from './services/auth-login.service';
import { environment } from './../environments/environment';
import { LoginService } from './services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent{

  title = 'Robasa';

  isExpanded = false;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  email: string = '';
  password: string = '';
  loggedIn: boolean = false;

  imgUser:string = "https://source.unsplash.com/random/60x60";
  user:string = "Anónimo";

  admin:boolean;
  picker:boolean;

  constructor(public auth: AuthService, public service: LoginService,private router: Router) {}

  ngOnInit() {
    this.loadProfile();
    this.auth.getRoles(this.auth.loadInLocalStorange("accessToken"));

    this.auth.authStatusAdmin.subscribe(value => this.admin = value);
    this.auth.authStatusPicking.subscribe(value => this.picker = value);
    this.auth.authStatus.subscribe((value) => (this.loggedIn = value));

    
  }

  //Login
  onSubmit() {
    if (this.password.trim() != '' && this.email.trim() != '') {
      var data = {
        email: this.email,
        password: this.password,
      };
      this.service.postLogin(data).subscribe(
        (data) => {
          this.checkLogin(data);
        },
        (error) => {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: '¡Tus credenciales son incorrectas, por favor vuelve a intentar!',
          });
        }
      );
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: '¡No se puedes dejar espacios en blanco!',
      });
      this.auth.logout();
      this.auth.changeAuthStatus(false);
    }
  }
  //Chequear la data del login
  checkLogin(data) {
    if (data != null) {
      Swal.fire({
        icon: 'success',
        title: '',
        text: '¡Has iniciado sesión!',
      });
      this.user = data.username;
      this.email = '';
      this.password = '';
      //Obtengo el usuario y lo guardo en localstorange
      if(data.photofilename != null){
        this.imgUser = environment.API_PHOTOS +"/" +data.photofilename;
      }

      this.auth.SetLocalStorange("accessToken",data.Token);
      this.auth.getDecodedAccessToken(this.auth.loadInLocalStorange("accessToken"));
      this.auth.changeAuthStatus(true);

      this.loadProfile();
      this.auth.getRoles(this.auth.loadInLocalStorange("accessToken"));
      this.router.navigateByUrl('');
    } else {
      Swal.fire({
        icon: 'error',
        title: '',
        text: '¡El email o contraseña son incorrectos!',
      });

      this.auth.logout();
      this.auth.changeAuthStatus(false);
    }
  }

  logout() {
    this.auth.logout();
    this.auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
    Swal.fire({
      icon: 'success',
      title: '',
      text: '¡Has cerrado la sesión!',
    });
  }

  show() {
    if (!this.isShowing) {
      this.isShowing = true;
      $('.full-width').hide();
      $('.show').show();
      $('.show-no').hide();
    } else {
      this.isShowing = false;
      $('.full-width').show();
      $('.show').hide();
      $('.show-no').show();
    }
  }

  onImgError(event) {
    event.target.src = 'https://source.unsplash.com/random/60x60';
  }

  loadProfile(){
    //Acán van a ir lo de roles
    this.imgUser = this.auth.loadInLocalStorange("image");
    this.user = this.auth.loadInLocalStorange("username");
  }


}
