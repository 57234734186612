import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PickService {
  readonly APIUrl=environment.API;

  constructor(private http:HttpClient) { }

  
  getHeaders(){
    let httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
    });
    return httpHeaders;
  }

  getPickList():Observable<any[]>{
    // return this.http.get<any>(this.APIUrl+'/PickList',{headers:this.getHeaders()});
    return this.http.post<any>(this.APIUrl+'/PickList/Sync',{headers:this.getHeaders()});
  }

  getPickDetails(absEntry:string):Observable<any[]>{
    return this.http.get<any>(this.APIUrl+'/PickDetails/'+absEntry,{headers:this.getHeaders()});
  }

  updatePickDetails(updateData: any):Observable<any[]>{
    return this.http.put<any>(this.APIUrl+'/AsignDetail', updateData, {headers:this.getHeaders()})
  }

  updatePickDetailsIncomplete(updateData: any):Observable<any[]>{
    return this.http.put<any>(this.APIUrl+'/AsignDetail/Incomplete', updateData, {headers:this.getHeaders()})
  }

  getPickListById(absEntry:string):Observable<any[]>{
    return this.http.post<any>(this.APIUrl+'/PickList/SyncSapDoc?absentry='+absEntry,{headers:this.getHeaders()});
  }
}
