import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AsignDetailService } from '../../../services/asign-detail.service';
import { languageSettings } from '../../../../environments/language-settings'
import { PickService } from '../../../services/pick.service';
import { AsignService } from 'app/services/asign.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AuthService } from 'app/services/auth-login.service';

@Component({
  selector: 'app-second-page',
  templateUrl: './second-page.component.html',
  styleUrls: ['./second-page.component.css']
})
export class SecondPageComponent implements OnInit {  

  @Input() id:any;
  AsignList:any=[];
  AsignDetail: any;
  isReadyForProcess: boolean = false;

  dtOptions: DataTables.Settings = {};
  // We use this trigger because fetching the list of asign can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  loading: boolean = false;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service:PickService,
    private serviceAsignDetail:AsignDetailService,
    private serviceAsign: AsignService,
    private _toastr: ToastrService,
    private serviceAuth: AuthService) {
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.dtOptions = {
      responsive: true,
      // pagingType: 'simple',
      pageLength: 10,
      order: [[ 1, "asc" ]],
      language: languageSettings,
      dom: 'l<"top"p>rft<"bottom"ip><"clear">'
    };

    this.refreshAsignList();
  }

  refreshAsignList(){
    this.loading = true;
    

    this.serviceAsignDetail.getAsiListByEmployee(this.id, this.serviceAuth.getUserId()).subscribe((data: any)=>{
      this.AsignDetail=data;
      this.AsignList = data.detail;
      
      this.checkIsAllCollected()
      this.dtTrigger.next();
      
      this.loading = false;  
    });

    /*this.serviceAsignDetail.getAsiList(this.id).subscribe((data: any)=>{
      this.AsignDetail=data;
      this.AsignList = data.detail;
      
      this.checkIsAllCollected()
      this.dtTrigger.next();
      this.loading = false;
    });*/
  }

  checkIsAllCollected() {
    let notCollected = this.AsignList.filter(({pickdateconfirmation}) => pickdateconfirmation == null);
    if(notCollected.length == 0) {
      this.isReadyForProcess = true;
      return true;
    } else {
      // Siempre permite accionar el boton de procesar
      this.isReadyForProcess = true;
      //return false
      return true
    }
  }

  setToProcessed() {
    if (this.checkIsAllCollected()) {
      let submitData: any = this.AsignDetail;
      delete submitData.detail;
      submitData.asignDetail = this.AsignList;

      /*this.serviceAsign.updateStatus(2, submitData).subscribe((data: any) => {
        this._toastr.success("¡Establecido como procesado!", "Operación exitosa")
        this.router.navigateByUrl('/piking');
      }, (err) => {
        this._toastr.error("incapaces de proceder!", "Error")
      })*/


    }
  }

  updateStatusForce() {
    let val = {
      "id" : this.id,
      "employee" : this.serviceAuth.getUserId()
    }
    
    this.serviceAsignDetail.setAsiFreeList(val).subscribe((data: any) => {
      this._toastr.success('¡Actualizado correctamente!');
    }, (err) => {
      this._toastr.error('no se puede actualizar, inténtelo de nuevo más tarde.');
    })

    this.router.navigateByUrl('/piking');
  }

  

  pickup(item: any) {
    item.pickdateconfirmation = new Date();
    item.moddate = new Date();
    item.incomplete = 0;
    item.status = 1;
    this.service.updatePickDetails(item).subscribe((data: any) => {
      this._toastr.success('¡Actualizado correctamente!');
      this.checkIsAllCollected(); 
    }, (err) => {
      this._toastr.error('no se puede actualizar, inténtelo de nuevo más tarde.');
    })

    this.serviceAsign.updateStatusForce('L',this.id).subscribe((data: any) => {
      
    }, (err) => {
      this._toastr.error("incapaces de proceder!", "Error")
    })
  }

  reset(item: any) {
    item.pickdateconfirmation = null;
    item.moddate = new Date();
    item.incomplete = 0;
    item.status = 0;
    this.service.updatePickDetails(item).subscribe((data: any) => {
      this._toastr.success('¡Actualizado correctamente!');
      this.checkIsAllCollected();
    }, (err) => {
      this._toastr.error('no se puede actualizar, inténtelo de nuevo más tarde.');
    })

    this.serviceAsign.updateStatusForce('L',this.id).subscribe((data: any) => {
      
    }, (err) => {
      this._toastr.error("incapaces de proceder!", "Error")
    })
  }

  incomplete(item: any) {
    item.pickdateconfirmation = null;
    item.moddate = new Date();
    item.incomplete = 1;
    item.status = 1;
    this.service.updatePickDetailsIncomplete(item).subscribe((data: any) => {
      this._toastr.success('¡Actualizado correctamente!');
      this.checkIsAllCollected(); 
    }, (err) => {
      this._toastr.error('no se puede actualizar, inténtelo de nuevo más tarde.');
    })

    this.serviceAsign.updateStatusForce('L',this.id).subscribe((data: any) => {
      
    }, (err) => {
      this._toastr.error("incapaces de proceder!", "Error")
    })
    
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
