import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { SecondPageComponent } from './pages/piking/second-page/second-page.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, 

  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }
    ]
  }, 

  {
    path: '**',
    redirectTo: 'dashboard'
  },

  {
    path: 'piking/:id',
    component: SecondPageComponent,
    pathMatch: 'full',
  }
]
