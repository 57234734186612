export const languageSettings: any = {
    "sProcessing":    "Procesando...",
    "sLengthMenu":    "Mostrar _MENU_ entradas",
    "sZeroRecords":   "No se encontraron registros coincidentes",
    "sEmptyTable":    "No hay datos disponibles en la tabla",
    "sInfo":          "Mostrando _START_ a _END_ de _TOTAL_ registros",
    "sInfoEmpty":     "Mostrando registros del 0 al 0 de un total de 0 registros",
    "sInfoFiltered":  "(filtrado de un total de _MAX_ registros)",
    "sInfoPostFix":   "",
    "sSearch":        "Búsqueda:",
    "sUrl":           "",
    "sInfoThousands":  ",",
    "sLoadingRecords": "Cargando...",
    "oPaginate": {
        "sFirst":    "Primera",
        "sLast":    "Última",
        "sNext":    "Próxima",
        "sPrevious": "Previa"
    },
    "oAria": {
        "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
        "sSortDescending": ": Activar para ordenar la columna de manera descendente"
    }
}